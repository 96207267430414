<!-- 充值记录 -->
<template>
  <div class="recharge-recode">
    <div class="recharge-list" v-if="list_show">
      <load-more
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        :totalCount="totalCount"
        @loadmore="loadmore"
      >
        <div
          class="recharge-item"
          v-for="(item, index) in recharge_list"
          :key="index"
        >
          <h3>
            账户充值<span class="price">￥{{ item.money || 0 }}</span>
          </h3>
          <p class="time">
            支付时间：<span>{{ item.created_at }}</span>
          </p>
          <p class="no">
            订单编号：<span>{{ item.order_num }}</span>
          </p>
        </div>
      </load-more>
    </div>
    <div class="no-list" v-else>
      <img
        class="no-img"
        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021629-101722.png"
      />
      <p class="no-txt">暂无充值记录哦~</p>
    </div>
  </div>
</template>

<script>
import { getData } from "@/api/user";
export default {
  data() {
    return {
      recharge_list: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      list_show: true,
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f7f7f7");
  },
  created() {
    this.get_recode();
  },
  methods: {
    get_recode() {
      getData({
        url: `api/charging/charge/recharge_record?page=${this.pageIndex}`,
        data: {},
      }).then((res) => {
        let data = res.data.data.data;
        console.log(data);
        if (res.data.code === 200) {
          this.pageSize = data.per_page;
          this.totalCount = data.total;
          let list = data.data;
          if (this.pageIndex == 1) {
            this.recharge_list = list;
            this.list_show = list.length <= 0 ? false : true;
          } else {
            this.recharge_list = this.recharge_list.concat(list);
          }
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    loadmore(pageIndex) {
      this.pageIndex = pageIndex;
      this.get_recode();
    },
  },
};
</script>

<style lang="less">
.recharge-recode {
  .recharge-list {
    padding: 12px 12px 50px;
  }
  .recharge-item {
    margin-bottom: 12px;
    padding: 19px 22px 19px 18px;
    background: #fff;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333;
      span {
        color: #ffae15;
      }
    }
    .time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 9px;
      span {
        color: #666;
      }
    }
    .no {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #666;
        flex: 1;
        margin-left: 20px;
        text-align: right;
      }
    }
  }
}
</style>